body {
  margin: 0 0 1em 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about-headshot {
  border: 3px solid rgba(68, 68, 221, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), 0 6px 25px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  max-width: 100%;
}

@media (min-width: 992px) {
  .about-headshot {
    width: 30%; /* or any other value less than 100% */
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.title {
  font-size: 2em;
  text-align: center;
  color: black;
  -webkit-text-fill-color: #858282;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-weight: 400;
  margin: 0 0 0.5em 0;
}

.content {
  padding-top: 7em;
  padding-bottom: 2em;
}

.portfolioViewContainer div {
  display: inline;
}

.resumePDFViewer, .portfolioPDFViewer {
  width: 90%;
  height: 80vh;
}

@media (min-width: 900px) {
  .resumePDFViewer, .portfolioPDFViewer {
    width: 70%;
  }

  .markdownContainer {
    width: 80%;
  }
}

:root {
  --media-max-height: 30vw;
}

div.markdownContainer img:not(:fullscreen),
div.markdownContainer .video-react:not(:fullscreen),
div.markdownContainer source:not(:fullscreen) {
  height: var(--media-max-height);
}

div.markdownContainer ul, ol {
  text-align: left;
  list-style-position: inside;
}

div.markdownContainer p, pre {
  text-align: left;
}

.imageAlt {
  text-align: center !important;
}

.markdownContainer div:hover p {
  opacity: 1;
}

.portfolioViewContainer {
  font-size: 1.25em;
  margin-left: 10vw !important;
  margin-right: 10vw !important;
}

.zoom-container {
  max-width: 100%;
  max-height: 100%;
}

iframe {
  pointer-events: scroll;
}

.zoom-container img, .zoom-container video {
  max-width: 100%;
  max-height: 100%;
}

/* Bugfix for code blocks */
pre code {
  padding: 0px !important;
  background-color: rgb(245, 242, 240) !important;
}

.zoom-container p {
  position: absolute;
  width: 100%;
  padding: 5px 0;
  bottom: 0px;
  left: 0px;
  color: black;
  background: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  font-size: 1.5vw;
  opacity: 0;
  transition: visibility 0s, opacity 0.1s linear;
}

.zoomed-text {
  opacity: 1 !important;
}

.invisible {
  display: none !important;
}

.about-text, .contact-text, .contact-text {
  display: flex;
  align-items: center; /* Vertically center-align the children */
  justify-content: flex-start;
  gap: 8px;
}

.about-text img, .contact-text img, .contact-text img {
  display: block; /* Convert the image to block-level */
  flex: 0 0 auto;
  margin-right: 15px;
}

.video-react {
  display: block !important;
  width: auto; /* Allows width to adjust based on height for portrait videos */
  max-width: 100%; /* Prevents overflow on wide screens */
  /* height: auto; */
  max-height: 80vh; /* Sets a max height to fit within the viewport */
  margin: 0 auto; /* Centers the video */
}

.center-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.highlight {
  color: #287cd5;
}